import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class massSet {
  [key: string]: any;

  public value: any;

  @Inject(HttpService) private http!: HttpService;

  // 20220601
  // 这个是专栏
  //群发消息专栏列表||||||||||||||||||||||||||||||||||||||
  public getPeopleList(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/sendMessagesGroup/list', param);
  }
  //创建群发消息 - 所属专栏 select数据  见wechat-imagetext-service  -> applicationsList

  // 群发消息 发送
  public send(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/sendMessagesGroup/sendMsg', param);
  }
  // 群发消息分组列表删除
  public deleGroup(param: Dict<any>): Promise<any> {
    return this.http.delete(`/api/application/sendMessagesGroup/${param.id}`);
  }
  // 撤回群发消息分组列表
  public revokeMessageGroup(param: Dict<any>): Promise<any> {
    return this.http.put(`/api/application/sendMessagesGroup/revoke/${param.id}`);
  }

  // 这里是群发消息分组||||||||||||||||||||||||||||||||||||||
  // 群发消息分组设置列表获取
  public getGroupSet(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/sendMessagesGroupSet/list', param);
  }
  // 群发消息分组设置列表删除
  public deleGroupSet(param: Dict<any>): Promise<any> {
    return this.http.delete(`/api/application/sendMessagesGroupSet/${param.id}`);
  }
  // 群发消息分组设置列表修改
  // public editGroupSet(param: Dict<any>): Promise<any> {
  //   return this.http.put('/api/application/sendMessagesGroupSet/', param);
  // }
  public editGroupSet(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/sendMessagesGroupSet/modify', param);
  }
  // 群发消息分组设置列表新增
  public addGroupSet(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/sendMessagesGroupSet', param);
  }
    // 群发消息分组设置查询信息
    public persnSearch(param: Dict<any>): Promise<any> {
      return this.http.post('/api/application/sendMessagesGroupSet/userAccountVerification', param);
    }

  // 这里是应用 ||||||||||||||||||||||||||||||||||||||
  // 群发消息 发送
  public channelSend(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/sendMessagesGroup/channel/sendMsg', param);
  }
  // 获取通道 /api/application/sendMessagesGroup/acceptChannels
  public getPassageway() {
    return this.http.get('/api/application/sendMessagesGroup/acceptChannels');
  }
  // 这里预览名单 ||||||||||||||||||||||||||||||||||||||
  // 通过id获取群发消息预览名单表信息
  public getRoster(param: Dict<any>): Promise<any> {
    return this.http.get('/api/application/sendMsgPreviewList/listAll', param);
  }
  // 新增群发消息预览名单表模块
  public addRoster(param: Dict<any>): Promise<any> {
    return this.http.post('/api/application/sendMsgPreviewList/', param);
  }
  // 删除群发消息预览名单表模块
  public deleRoster(param: Dict<any>): Promise<any> {
    return this.http.delete(`/api/application/sendMsgPreviewList/${param.id}`);
  }
  // 修改群发消息预览名单表模块
  public editRoster(param: Dict<any>): Promise<any> {
    return this.http.put('/api/application/sendMsgPreviewList/', param);
  }

  // 人员查询
  // 查询列表
  public getPersonList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/hr/channel/userListPage', param);
  }
  // 人员列表全选
  public suretPersonList(param: Dict<any>): Promise<any> {
    return this.http.post('/api/hr/channel/userListStr', param);
  }
}
