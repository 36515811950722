





















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import moment from 'moment';
//引入组件
import { WechatImageTextService } from '@/services/wechat-imagetext-service';
import { massSet } from '@/services/massage-service';

@Component({
  components: {
  }
})
export default class creatRoster extends Vue {
  public labelPosition: String = 'top';
  public form: any = {
    acceptChannelId: '',
    sendItem: ''
  };
  public queryParams: any = { //查询参数 
    acceptChannelId: '',
    total: 0
  };
  public querySend: any = {
    channelId: ''
  }
  public rules: any = {
    acceptChannelId: [
      { required: true, message: '请选择专栏', trigger: ['blur', 'change'] }],
    sendItem: [
      { required: true, message: '请输入预览名单', trigger: ['blur', 'change'] }],
  }
  private applications: any[] = []; //存储专栏
  private sendMessageList: any = {}; //存储群发消息分组
  public prewType: string = '新增';//默认预览名单是新增
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  @Inject(massSet) private massSet!: massSet;
  @Inject(WechatImageTextService) private wechatImageTextService!: WechatImageTextService;

  public async created(): Promise<void> {
    this.openFullScreen2();
    //查询专栏
    await this.applicationsList()

  }

  //获取专栏
  public async applicationsList(): Promise<void> {
    const res = await this.wechatImageTextService.applicationsList();
    this.applications = res
    if (this.applications.length > 0) {//如果是添加的话，默认选中一个专栏
      this.form.acceptChannelId = this.applications[0].id
      this.$set(this.form, 'acceptChannelId', this.form.acceptChannelId)
      this.agentChanged(this.form.acceptChannelId)
    }
  }
  //获取预览名单
  public async sendMessageListAll(): Promise<void> {
    // 根据图文获取指定分组列表 只有图文消息才有参数  channelAgentId
    let param = {
      acceptChannelId: this.queryParams.acceptChannelId,
    }
    const res = await this.massSet.getRoster(param);
    // 判断res【0】有没有sendItem的字段 有说明是更新 如果没有是新增
    if (res.length > 0) {
      this.sendMessageList = res[0];
      if (this.sendMessageList.id) {
        this.prewType = "修改"
        this.form.sendItem = this.sendMessageList.sendItem.replace(/\s*/g,"");

      } else {
        // 无内容
        this.prewType = "新增"
        this.form.sendItem = ''
      }
    } else {
      // 无内容
      this.prewType = "新增"
      this.form.sendItem = ''
    }

  }

  public async agentChanged(val: any) {
    this.queryParams.acceptChannelId = val
    //查询指定分组
    await this.sendMessageListAll()
  }
  // 去除空格
  public replaceEmpty(val: any){
    console.log(val)
    this.form.sendItem = val.replace(/\s*/g,"");
  }
  //保存
  public async submitForm(formName: any): Promise<void> {
    const addRef = (this.$refs.form as any)
    addRef.validate(async (valid: any) => {
      if (valid) {
        if (this.prewType == '修改') {
          this.form.id = this.sendMessageList.id;
          const res = await this.massSet.editRoster(this.form);
          if (res) {
            this.$message({
              message: '修改名单成功！',
              type: 'success'
            });
            this.$router.push("/groupMessagesSpecial");

          } else {
            this.$message({
              message: res.message,
              type: 'error'
            });

          }
        } else {
          // 新增
          console.log('144',this.form.sendItem)
          const res = await this.massSet.addRoster(this.form);
          if (res) {
            this.$message({
              message: '新增名单成功！',
              type: 'success'
            });
            this.$router.push("/groupMessages");

          } else {
            this.$message({
              message: res.message,
              type: 'error'
            });

          }
        }
      }
    })

  }
  //返回
  public async handleGoBack(): Promise<void> {
    this.$router.go(-1)
  }
  // loading
  openFullScreen2() {
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    setTimeout(() => {
      loading.close();
    }, 1000);
  }
  /**
   * -END- CALL SERVICE
   */
}
